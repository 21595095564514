import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "../../styles/project/carousel.css";
import CustImg from './project-carousel-image'
import { Link, graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Layout from "../layout"
import SEO from "../seo"
import { format } from "date-fns"
import Img from "gatsby-image"

export default ({ data, location }) => {
  const { mdx } = data; // data.markdownRemark holds our post data
  const { frontmatter, body } = mdx;
  return (
      <div style={{overflowX: "hidden"}}>
        <Layout>
          <SEO title={frontmatter.title}/>
          <div>
            <div className="row container mx-auto">
              <div className="col-12 px-0 mb-3 d-flex back-btn-container">
                {"< "}
                <Link to="/projects" className="back-btn"> 
                  <h5 className="m-0 pl-2">
                    {`Back to projects`}
                  </h5>
                </Link>
              </div>
              <div className="col-12 px-0 mt-1">
                {
                  frontmatter.childImageSharp !== undefined
                    ?
                    <Img
                      fluid={frontmatter.coverImg.childImageSharp.fluid}
                      className="project-cover"
                    />
                    :
                    null
                }
                <div className="mb-5 mt-4">
                  <div className="tech-stack-container d-none d-md-block">
                    <h5 className=""><b>Tags</b></h5>
                    {frontmatter.tags.map((tag) => (
                      <div key={frontmatter.title+"-"+tag} className="project-tag text-nowrap my-1 mx-1">
                        <p className="mb-0" >
                          {tag}
                        </p>
                      </div>
                    ))}
                  </div>
                  <h1 className="mt-3 mb-0 article-title">
                    {frontmatter.title}
                  </h1>
                  <h2 className="article-summary">
                    {frontmatter.desc}
                  </h2>
                  <div className="tech-stack-container d-block d-md-none">
                    {frontmatter.tags.map((tag) => (
                      <div key={frontmatter.title+"-"+tag} className="project-tag text-nowrap my-1 mx-1">
                        <p className="mb-0" >
                          {tag}
                        </p>
                      </div>
                    ))}
                  </div>
                  <h6 className="mt-0 mb-2 d-block">
                    {format(new Date(frontmatter.startDate), "dd MMM yyyy - ")}
                    {format(new Date(frontmatter.lastUpdated), "dd MMM yyyy")}
                  </h6>
                </div>
                <div className={`article`}>
                  <MDXProvider 
                    components={{
                      CustImg,
                      Carousel: (props) => <Carousel infiniteLoop autoPlay interval={7000} transitionTime={1000} showStatus={false} showThumbs={false}
                      renderArrowPrev={(onClickHandler, hasPrev, label) =>
                        hasPrev && (
                          // onMouseDown={e => {e.preventDefault()}} prevents the button from being made active when clicked,... Which is clever, I love it
                          <button type="button" onMouseDown={e => {e.preventDefault()}} onClick={onClickHandler} title={label} className="carousel-btn" style={{ left: -25 }}>
                            <div className="carousel-btn-arrow prev" />
                          </button>
                        )
                      }
                      renderArrowNext={(onClickHandler, hasNext, label) =>
                        hasNext && (
                          <button type="button" onMouseDown={e => {e.preventDefault()}} onClick={onClickHandler} title={label} className="carousel-btn" style={{ right: -25 }}>
                            <div className="carousel-btn-arrow next" />
                          </button>
                        )
                      } {...props} />
                    }}
                  >
                    <MDXRenderer>{body}</MDXRenderer>
                  </MDXProvider>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </div>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    mdx(frontmatter: { path: { eq: $path } }) {
      body
      frontmatter {
        title
        desc
        startDate
        lastUpdated
        tags
        coverImg {
          childImageSharp {
            fluid(maxWidth: 1000) {
              # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`
