import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import Zoom from 'react-medium-image-zoom';
import '../../styles/project/carousel-img-zoom.css';

export default function CustImg({ imgName, imgAlt }) {
  const { allImageSharp } = useStaticQuery(graphql`
      query {
          allImageSharp {
              edges {
                  node {
                      fluid(maxWidth: 1000) {
                        ...GatsbyImageSharpFluid
                        originalName
                      }
                  }
              }
          }
      }
  `);
  const image = allImageSharp.edges.find(
      edge => edge.node.fluid.originalName === imgName
  );
  if (!image) {
      return null;
  }
  return <Zoom overlayBgColorEnd="rgba(255, 255, 255, 0.8)" zoomMargin={100}><Img style={{width: "100%", height: "100%"}} fluid={image.node.fluid} imgAlt={imgAlt} /></Zoom>;
}